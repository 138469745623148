<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Wiedervorlage
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        autocomplete="off"
        @submit.prevent
      >
        <BasicTypeahead
          id="typeahead-order"
          v-model="resubmission.order"
          :query="searchOrders"
          :result-formatter="orderResultFormatter"
          :input-formatter="orderInputFormatter"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Vertragsnummer"
          :horizontal="true"
          label="Vertragsnummer"
          strict
          @hit="setOrder"
          @unselect="unsetOrder"
        />
        <template v-if="resubmission.order">
          <div
            v-if="resubmission.order.carData && resubmission.order.carData.kennzeichen"
            class="row"
          >
            <p class="col-12 col-md-4">
              Kennzeichen
            </p>
            <p class="col-12 col-md-8">
              {{ resubmission.order.carData.kennzeichen }}
            </p>
          </div>
          <div
            v-if="resubmission.order.classicCar"
            class="row"
          >
            <p class="col-12 col-md-4">
              Fahrzeug
            </p>
            <p class="col-12 col-md-8">
              {{ resubmission.order.classicCar.Hersteller }}  {{ resubmission.order.classicCar.Modellname }}  {{ resubmission.order.classicCar.Karosserie ? resubmission.order.classicCar.Karosserie : wkz[resubmission.order.wkz].human }}
            </p>
            <p class="col-12 col-md-4">
              Typ
            </p>
            <p class="col-12 col-md-8">
              {{ hsOrderTypes[resubmission.order.hsOrderType].human }}
            </p>
          </div>
          <div class="row">
            <p class="col-12 col-md-4">
              Status
            </p>
            <p class="col-12 col-md-8">
              {{ hsOrderStatus[resubmission.order.hsOrderStatus].human }}
            </p>
          </div>
        </template>
        <BasicTypeahead
          id="typeahead-customer"
          v-model="resubmission.customer"
          :query="searchCustomers"
          :result-formatter="customerFormatter"
          :input-formatter="customerFormatter"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Kunde"
          :horizontal="true"
          label="Kunde"
          strict
          :disabled="resubmission.order"
          @hit="setCustomer"
          @unselect="unsetCustomer"
        />
        <BasicTypeahead
          id="typeahead-vermittler"
          v-model="resubmission.vermittler"
          :query="searchVermittler"
          :input-formatter="userFormatter"
          :result-formatter="userFormatter"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Vermittler"
          :horizontal="true"
          label="Vermittler"
          strict
          :disabled="resubmission.order"
          @hit="setVermittler"
          @unselect="unsetVermittler"
        />
        <BasicSelect
          v-model="resubmission.category"
          class="mb-3"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Kategorie auswählen"
          :horizontal="true"
          :margin="false"
          label="Kategorie"
          :options="humanCategoryOptions"
        />
        <BasicInput
          v-model="resubmission.subject"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Betreff"
          :horizontal="true"
          required
          label="Betreff"
        />

        <div class="form-group mb-0">
          <div class="form-row">
            <div class="col-12 col-md-4">
              Gesprächsgrund
            </div>
            <div
              class="col-12 col-md-8"
            >
              <basic-textarea
                v-model="resubmission.reason"
                placeholder="Gesprächsgrund"
                :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
                :margin="false"
              />
            </div>
          </div>
        </div>

        <div class="form-row my-3">
          <div class="col-12 col-md-8 mb-0 pb-0">
            <basic-datepicker
              v-model="resubmission.dueDate"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Fällig am"
              placeholder="TT.MM.JJJJ"
              :margin="false"
              required
            />
          </div>
          <div class="col-12 col-md-4">
            <div class="d-flex justify-content-center align-items-center">
              <basic-select
                v-model="timeHours"
                :columns="{input:['col-12', 'col-md-12']}"
                placeholder="HH"
                :options="hours"
                :horizontal="true"
                :margin="false"
                required
                @input="setTime"
              />
              <span class="mx-1 mb-0">
                :
              </span>
              <basic-select
                v-model="timeMinutes"
                :columns="{input:['col-12', 'col-md-12']}"
                placeholder="MM"
                :options="minutes"
                :horizontal="true"
                :margin="false"
                required
                @input="setTime"
              />
              <span class="ml-1">Uhr</span>
            </div>
          </div>
        </div>

        <BasicTypeahead
          id="typeahead-bearbeiter"
          v-model="resubmission.bearbeiter"
          :query="searchBearbeiter"
          :input-formatter="userFormatter"
          :result-formatter="userFormatter"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Bearbeiter"
          :horizontal="true"
          label="Bearbeiter"
          strict
          @hit="setBearbeiter"
          @unselect="unsetBearbeiter"
        />
        <div class="form-row">
          <div class="col-12 col-md-4">
            Ist erledigt
          </div>
          <div class="col-12 col-md-8 d-flex align-items-center">
            <basic-checkbox
              v-model="resubmission.isDone"
              :margin="false"
            />
          </div>
        </div>
      </form>
    </template>
  </ca-card>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import BasicTextarea from '@/components/BaseComponents/BasicTextarea.vue'
import CaCard from '@/components/Card.vue'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker.vue'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox.vue'
import resubmitCategory from '@/resources/enums/resubmitCategory'
import getResubmitTime from '@/helpers/getResubmitTime'
import feathers from '@/api'
import wkz from '@/resources/enums/wkz'
import hsOrderTypes from '@/resources/enums/hsOrderTypes'
import hsOrderStatus from '@/resources/enums/hsOrderStatus'
import dayjs from 'dayjs'

export default {
  name: 'ResubmitForm',
  components: {
    BasicInput,
    BasicTypeahead,
    BasicSelect,
    CaCard,
    BasicDatepicker,
    BasicTextarea,
    BasicCheckbox
  },
  props: {
    resubmission: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      default: null
    },
    customer: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      resubmitCategory,
      timeHours: '00',
      timeMinutes: '00',
      hsOrderStatus,
      hsOrderTypes,
      wkz
    }
  },
  computed: {
    humanCategoryOptions () {
      return Object.values(this.resubmitCategory).map(category => ({ label: category.human, value: category.api }))
    },
    hours () {
      return Array.from(Array(24).keys()).map(hours => ({ label: (hours < 10 ? '0' : '') + hours, value: (hours < 10 ? '0' : '') + hours }))
    },
    minutes () {
      return Array.from(Array(60).keys()).map(minutes => ({ label: (minutes < 10 ? '0' : '') + minutes, value: (minutes < 10 ? '0' : '') + minutes }))
    }
  },
  watch: {
    order: {
      handler () {
        if (!this.order) return
        this.setOrder(this.order)
      },
      immediate: true
    },
    customer: {
      handler () {
        if (!this.customer) return
        this.setCustomer(this.customer)
      },
      immediate: true
    }
  },
  created () {
    this.$set(this.resubmission, 'dueDate', dayjs().toDate())
  },
  methods: {
    searchUsers (term, roleIds) {
      return feathers.service('users').find({
        query: {
          $client: { lookupRoleId: roleIds },
          $limit: 25,
          $or: [
            { lastname: { $regex: '^' + term, $options: 'i' } },
            { firstname: { $regex: '^' + term, $options: 'i' } },
            { agentNumber: { $regex: '^' + term, $options: 'i' } },
            { traderId: { $regex: '^' + term, $options: 'i' } }
          ]
        }
      })
    },
    // Typeahead Bearbeiter
    searchBearbeiter (term) {
      return this.searchUsers(term, ['developer', 'admin', 'mitarbeiter'])
    },
    userFormatter (user) {
      return `${user.firstname} ${user.lastname} (${user.traderId}) - ${user.agentNumber}`
    },
    setUserId (user) {
      this.resubmission.userId = user._id
    },
    setBearbeiter (bearbeiter) {
      this.$set(this.resubmission, 'bearbeiterId', bearbeiter._id)
      this.$set(this.resubmission, 'bearbeiter', bearbeiter)
    },
    unsetBearbeiter () {
      this.$set(this.resubmission, 'bearbeiterId', null)
      this.$set(this.resubmission, 'bearbeiter', null)
    },

    // Typeahead Vermittler
    searchVermittler (term) {
      return this.searchUsers(term, ['makler'])
    },
    setVermittler (vermittler) {
      this.$set(this.resubmission, 'vermittlerId', vermittler._id)
      this.$set(this.resubmission, 'vermittler', vermittler)
    },
    unsetVermittler () {
      this.$set(this.resubmission, 'vermittlerId', null)
      this.$set(this.resubmission, 'vermittler', '')
    },
    // Typeahead Orders
    searchOrders (term) {
      return feathers.service('hs-order').find({
        query: {
          $limit: 25,
          $or: [
            { number: { $regex: term, $options: 'i' } }
          ]
        }
      })
    },
    unsetOrder () {
      this.$set(this.resubmission, 'orderId', null)
      this.$set(this.resubmission, 'order', null)
      this.$set(this.resubmission, 'orderNumber', null)
    },
    async setOrder (order) {
      this.unsetVermittler()
      this.unsetCustomer()
      this.$set(this.resubmission, 'orderId', order._id)
      this.$set(this.resubmission, 'order', order)
      this.$set(this.resubmission, 'orderNumber', order.number)

      const customer = await feathers.service('customer').get(order.customerId)
      this.setCustomer(customer)

      if (order.agentNumber) {
        const vermittler = await feathers.service('users').find({
          query: {
            agentNumber: order.agentNumber
          }
        })
        this.setVermittler(vermittler.data[0])
      }

      this.$set(this.resubmission, 'category', order.hsOrderType)
    },
    orderResultFormatter (order) {
      const customer = this.customerFormatter(order.customer)
      return `${order.number} - ${order.customer.name.VOR ? order.customer.name.VOR : ''} ${customer} (${order.classicCar.Hersteller} ${order.classicCar.Modellname} ${order.classicCar.Karosserie ? order.classicCar.Karosserie : wkz[Number(order.wkz)].human})`
    },
    orderInputFormatter (order) {
      return order.number
    },
    // Typeahead Customers
    searchCustomers (term) {
      return feathers.service('customer').find({
        query: {
          $limit: 25,
          $or: [
            { 'name.VOR': { $regex: '^' + term, $options: 'i' } },
            { 'name.NAM': { $regex: '^' + term, $options: 'i' } }
          ]
        }
      })
    },
    customerFormatter (customer) {
      if (!customer.name) return customer
      return (customer.name.ANR === 'f' || customer.kind === 'FIRMA')
        ? [customer.name.NAM, customer.address.ZUSATZ1, customer.address.ZUSATZ2, customer.address.ZUSATZ3].filter(p => p).join(' ')
        : `${customer.name.VOR} ${customer.name.NAM}`
    },
    setCustomer (customer) {
      this.$set(this.resubmission, 'customerId', customer._id)
      this.$set(this.resubmission, 'customer', customer)
    },
    unsetCustomer () {
      this.$set(this.resubmission, 'customerId', null)
      this.$set(this.resubmission, 'customer', null)
    },
    setTime () {
      this.resubmission.dueDate = getResubmitTime(this.resubmission.dueDate, this.timeHours, this.timeMinutes)
    }
  }
}
</script>
