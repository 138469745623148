module.exports = {
  3: { api: '3', human: 'Kraftrad', gdv: '1' },
  112: { api: '112', human: 'PKW', gdv: '2', allowFahrerschutz: true },
  127: { api: '127', human: 'Wohnmobil', gdv: '8', allowFahrerschutz: true },
  251: { api: '251', human: 'Lieferwagen bis 3,5t zul. Gesamtgewicht', gdv: '4', allowFahrerschutz: true },
  351: { api: '351', human: 'LKW über 3,5t zul. Gesamtgewicht', gdv: '4' },
  451: { api: '451', human: 'Traktor / landwirtsch. Zugmaschine', gdv: '5' },
  481: { api: '481', human: 'Zugmaschine', gdv: '5' },
  581: { api: '581', human: 'Anhänger', gdv: '7' },
  621: { api: '621', human: 'Omnibus', gdv: '3' },
  719: { api: '719', human: 'Sonstiges', gdv: '8' }
}

// gdv nach api/src/resources/helpers/getWKZByClassicCar.js und https://www.kba.de/SharedDocs/Downloads/DE/Statistik/SV/sv1_2022_02_pdf.pdf;jsessionid=8009450B569F88D115E90D7D83D690E4.live11311?__blob=publicationFile&v=10
